<template>
  <div class="wrapper">
    <!-- <keep-alive>
      <router-view class="router-view" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view class="router-view" v-if="!$route.meta.keepAlive" /> -->
    <router-view class="router-view" />

    <van-tabbar route>
      <van-tabbar-item icon="home-o" to="/home">首页</van-tabbar-item>
      <van-tabbar-item icon="friends-o" to="/customer/index">客户</van-tabbar-item>
      <van-tabbar-item icon="friends-o" to="/contract/index">合同</van-tabbar-item>
      <van-tabbar-item icon="setting-o" to="/case/index">案件</van-tabbar-item>
      <van-tabbar-item icon="manager-o" to="/user/index">我的</van-tabbar-item>
    </van-tabbar>
    <GlobalGet />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
<style scoped>
.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.router-view {
  height: calc(100% - 50px);
  padding-bottom: 50px;
}
</style>
