import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import '@/permission' // permission control
// 引入vant
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import '@/styles/animate.css'
import '@/styles/index.scss' // global css

/** 事件传递 */
import VueBus from 'vue-bus'
Vue.use(VueBus)

// 注册全局过滤器
import * as filters from './filters' // global filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 处理时间的过滤器
// Vue.use(require('vue-moment'))
import moment from 'moment'
moment.locale('zh_cn')

// 表情
import { emoji } from './utils/emoji'
Vue.prototype.emoji = emoji

Vue.config.productionTip = false

// 全局接案组件
import GlobalGet from '@/components/commonComponents/GlobalGet'
Vue.component('GlobalGet', GlobalGet)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
