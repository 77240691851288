<template>
  <div class="a">
    <div>是否接案&nbsp;</div>
    <el-switch
      v-model="switchVal"
      :active-value="1"
      :inactive-value="2"
      active-color="#1E90FF"
      inactive-color="#B0C4DE"
      @change="onChange"
    />
  </div>
</template>

<script>
import { commonEditUser } from '@/api/common'

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      userinfo: {},
      switchVal: 2
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    onChange() {
      commonEditUser({ open: this.switchVal }).then(res => {
        if (res.code == 200) {
          this.$notify({
            message: '接案状态编辑成功',
            type: 'success'
          })
          this.$store.dispatch('user/getInfo')
        }
      })
    },
    init() {
      var userinfo = JSON.parse(localStorage.getItem('loginUserInfo'))
      if (userinfo && userinfo.open) {
        this.userinfo = userinfo
        this.switchVal = userinfo.open
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.a {
  position: fixed;
  margin-left: 30px;
  top: 65%;
  right: 3%;
  display: flex;
  align-items: center;
  background: rgb(207, 207, 207);
  opacity: 0.9;
  padding: 5px 10px;
  border-radius: 20px;
  z-index: 999;
}
</style>
